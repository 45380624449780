import React from 'react'
import './CSoon.scss'

function CSoon() {
  return (
    <div className='wrapper'>
        <h1>Coming soon stay tuned...</h1>
    </div>
  )
}

export default CSoon